<!--suppress CssInvalidPseudoSelector -->
<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div>
    <DataGridLayout>
      <template v-slot:search>
        <DataGridSearch
          class="grid-search"
          store-module="vehicle"
          :initial-value="searchQuery"
          :placeholderText="$t('search')"
          :custom-filter="customFilter"
        >
        </DataGridSearch>
      </template>
      <template v-slot:action>
        <VehicleDialog
          v-if="canAddVehicle && displayAddVehicle"
          @vehicle-created-success="displaySnackBar($event)"
          @vehicle-creation-failed="displayErrorSnackBar($event)"
        >
        </VehicleDialog>
      </template>
      <template v-slot:metrics>
        <v-layout row wrap>
          <v-flex xs12 v-if="displayFilterButtons" data-qa="filter-buttons">
            <FilterButton
              class="ml-0 col-md-6"
              name="action-required"
              :selected="showAllActionRequired"
              :label="$t('vehicle.toolbar.allActionRequired')"
              :info="actionRequiredCount"
              @click="setFilterFlag('Action')"
            >
              <VehicleStatusIcon
                slot="icon"
                :color="actionBtnSelectedColor"
                :status="'ACTION'"
              />
            </FilterButton>
            <FilterButton
              name="processing"
              class="col-xs-12 col-md-6"
              :selected="showAllProcessing"
              :label="$t('vehicle.toolbar.allProcessing')"
              :info="processingCount"
              @click="setFilterFlag('Processing')"
            >
              <VehicleStatusIcon
                slot="icon"
                :color="processingBtnSelectedColor"
                :status="'PROCESSING'"
              />
            </FilterButton>
            <FilterButton
              name="subscribed"
              :selected="showAllSubscribed"
              :label="$t('vehicle.toolbar.allSubscribed')"
              :info="subscribedCount"
              @click="setFilterFlag('Subscribed')"
            >
              <VehicleStatusIcon
                slot="icon"
                :color="subscribedBtnSelectedColor"
                :status="'SUBSCRIBED'"
              />
            </FilterButton>
          </v-flex>
          <v-flex xs12>
            <span v-if="toggleExternalFilterChip">
              <v-chip
                v-if="displayCloseChip"
                v-model="externalFilterChip"
                data-qa="externalFilterChip"
                close
                color="secondary-lighten5"
                text-color="primary"
                class="external-filter-chip mt-2 pl-0 ml-0"
              >
                {{ getExternalFilterDisplay() }}
              </v-chip>
              <v-chip
                v-else
                v-model="externalFilterChip"
                data-qa="externalFilterChip"
                color="secondary-lighten5"
                text-color="primary"
                class="external-filter-chip mt-2 pl-0 ml-0"
              >
                {{ getExternalFilterDisplay() }}
              </v-chip>
            </span>
          </v-flex>
          <v-flex shrink align-self-center>
            <VehicleFilterTotal />
          </v-flex>
          <v-flex grow class="toolbar">
            <v-flex
              md12
              lg8
              data-qa="vehicle-action-toolbar"
              :id="`${uniqueId}-vehicle-action-toolbar`"
            >
              <v-slide-y-reverse-transition>
                <v-layout v-if="selectedLength > 0" row>
                  <v-flex align-self-center shrink>
                    <div
                      data-qa="selected-count"
                      class="mr-2"
                      :id="`${uniqueId}-selected-count`"
                    >
                      <v-chip label color="primary" text-color="white" small>
                        {{ selectedLength }}
                      </v-chip>
                      <span class="selected-count-text">{{
                        $t('vehicle.toolbar.selected')
                      }}</span>
                    </div>
                  </v-flex>
                  <v-flex>
                    <v-menu v-model="showMenu">
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-if="displayBulkActionButton"
                          color="primary"
                          class="text-none"
                          data-qa="bulk-actions-button"
                          v-on="on"
                        >
                          {{ $t('vehicle.toolbar.bulkActions') }}
                          <v-icon
                            data-qa="bulk-action-dropdown"
                            class="white--text pl-1 align-start"
                            size="23"
                          >
                            ic-down
                          </v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-tile
                          v-for="(item, index) in activeBulkActions"
                          :key="index"
                          :data-qa="'bulk-actions-dropdown-' + item.dataQa"
                          :disabled="isBulkActionDisabled(index)"
                          @click="'';"
                        >
                          <ConfirmationDialogWithDropDown
                            :title="item.title"
                            :message="item.message"
                            :acceptance-text="item.acceptanceText"
                            :cancellation-text="item.cancellationText"
                            :label="item.label"
                            :dropDownItems="productsInProductFamilies"
                            :itemRule="itemRule"
                            :is-multi-select="item.isMultiSelect"
                            :is-churn-required="item.isChurnRequired"
                            @selectedItemChanged="
                              setSelectedProductFamilyId($event)
                            "
                            @confirm="
                              item.action($event);
                              showMenu = false;
                              selectedProductFamilyId = null;
                            "
                            @cancel="
                              showMenu = false;
                              selectedProductFamilyId = null;
                            "
                            :ref="'confirmationDialogWithDropDown_' + index"
                            v-if="
                              item.dialog === 'confirmationDialogWithDropDown'
                            "
                            :data-qa-key="item.dataQa"
                          >
                            <template v-slot:content>
                              <p
                                v-bind:key="vehicle.id"
                                v-for="vehicle in selected"
                              >
                                <span class="text-left list-item">{{
                                  vehicle.vin
                                }}</span>
                              </p>
                            </template>
                            <template v-slot:activator="{ on }">
                              <v-list-tile-title
                                v-on="on"
                                data-qa="activatorBtn"
                                @click="
                                  getProductFamilies(
                                    item.getEnrolledProductFamilies
                                  )
                                "
                              >
                                {{ item.name }}
                              </v-list-tile-title>
                            </template>
                          </ConfirmationDialogWithDropDown>
                          <ConfirmationDialogWithDropDown
                            :title="item.title"
                            :message="item.message"
                            :acceptance-text="item.acceptanceText"
                            :cancellation-text="item.cancellationText"
                            :label="item.label"
                            :drop-down-items="availableGroups"
                            :item-rule="item.rule"
                            :is-multi-select="item.isMultiSelect"
                            @confirm="
                              item.action($event);
                              showMenu = false;
                            "
                            @cancel="showMenu = false"
                            :data-qa-key="item.dataQa"
                            v-if="
                              item.dialog ===
                              'confirmationDialogWithDropDownForGroups'
                            "
                          >
                            <template v-slot:content>
                              <p
                                v-bind:key="vehicle.id"
                                v-for="vehicle in selected"
                              >
                                <span class="text-left list-item">{{
                                  vehicle.vin
                                }}</span>
                              </p>
                            </template>
                            <template v-slot:activator="{ on }">
                              <v-list-tile-title
                                v-on="on"
                                @click="getAvailableGroups(item.isRemove)"
                              >
                                {{ item.name }}
                              </v-list-tile-title>
                            </template>
                          </ConfirmationDialogWithDropDown>
                          <ConfirmationDialog
                            :title="item.title"
                            :message="item.message"
                            :acceptance-text="item.acceptanceText"
                            :data-qa-key="`${uniqueId}-${item.dataQa}`"
                            @confirm="
                              item.action($event);
                              showMenu = false;
                            "
                            @cancel="showMenu = false"
                            @setEnableDialogFalse="showMenu = false"
                            v-if="
                              item.dialog === 'confirmationDialog' &&
                              !item.deleteAction
                            "
                          >
                            <template v-slot:content>
                              <p
                                v-bind:key="vehicle.id"
                                v-for="vehicle in selected"
                              >
                                {{ vehicle.vin }}
                              </p>
                            </template>
                            <template v-slot:activator="{ on }">
                              ${{ item.name }}
                              <v-list-tile-title v-on="on"
                                >{{ item.name }}
                              </v-list-tile-title>
                            </template>
                          </ConfirmationDialog>
                          <VehicleBulkModifyDialog
                            :selectedVehicles="selected"
                            @cancel="showMenu = false"
                            @deselect="clearSelected()"
                            @vehicle-modified-success="displaySnackBar($event)"
                            @vehicle-modified-failure="
                              displayErrorSnackBar($event)
                            "
                            v-if="item.dialog === 'vehicleBulkModifyDialog'"
                          >
                            <template v-slot:activator="{ on }">
                              <v-list-tile-title v-on="on"
                                >{{ item.name }}
                              </v-list-tile-title>
                            </template>
                          </VehicleBulkModifyDialog>
                          <ConfirmationDialog
                            :title="confirmationDialog.confirmationTitle"
                            :message="confirmationDialog.confirmationMessage"
                            :acceptance-text="confirmationDialog.acceptanceText"
                            :cancellation-text="confirmationDialog.cancelText"
                            :display-confirm="confirmationDialog.displayConfirm"
                            :is-churn-required="
                              confirmationDialog.isChurnRequired
                            "
                            :data-qa-key="`${uniqueId}-${confirmationDialog.dataQa}`"
                            @confirm="
                              item.action($event);
                              showMenu = false;
                            "
                            :ref="'confirmationDialog'"
                            @cancel="
                              showMenu = false;
                              clearBulkDeleteObjects();
                            "
                            @setEnableDialogFalse="showMenu = false"
                            v-if="
                              item.dialog === 'confirmationDialog' &&
                              item.deleteAction
                            "
                          >
                            <template
                              v-if="processingVehicles.length > 0"
                              v-slot:content
                            >
                              <p
                                v-bind:key="vehicle.displayName"
                                v-for="vehicle in processingVehicles"
                              >
                                {{ vehicle.displayName }}
                              </p>
                            </template>
                            <template
                              v-else-if="enrolledVehicles.length > 0"
                              v-slot:content
                            >
                              <p
                                v-bind:key="vehicle.displayName"
                                v-for="vehicle in enrolledVehicles"
                              >
                                {{ vehicle.displayName }}
                              </p>
                            </template>
                            <template
                              v-else-if="
                                bulkDelete && consentedVehicles.length > 0
                              "
                              v-slot:content
                            >
                              <div
                                v-bind:key="vehicle.vin"
                                v-for="vehicle in consentedVehicles"
                              >
                                <div
                                  v-if="
                                    vehicle.consentedConsumers &&
                                    vehicle.consentedConsumers.length > 0
                                  "
                                >
                                  <span class="text-left consent-list-item">{{
                                    vehicle.displayName
                                  }}</span>
                                  <span class="text-right consent-list-item">{{
                                    vehicle.consentedConsumers &&
                                    vehicle.consentedConsumers.length > 0
                                      ? vehicle.consentedConsumers[0].name
                                      : ''
                                  }}</span>
                                </div>
                              </div>
                            </template>
                            <template v-else v-slot:content>
                              <p
                                v-bind:key="vehicle.displayName"
                                v-for="vehicle in notEnrolledVehicles"
                              >
                                {{ vehicle.displayName }}
                              </p>
                            </template>
                            <template v-slot:activator="{ on }">
                              <v-list-tile-title
                                v-on="on"
                                @click="attemptMarkForDelete(item.deleteAction)"
                                >{{ item.name }}
                              </v-list-tile-title>
                            </template>
                          </ConfirmationDialog>
                        </v-list-tile>
                      </v-list>
                    </v-menu>
                  </v-flex>
                  <v-flex>
                    <v-btn
                      block
                      flat
                      color="primary"
                      class="text-none"
                      data-qa="deselect-all"
                      @click.stop="clearSelected()"
                    >
                      {{ $t('vehicle.toolbar.deselectAll') }}
                    </v-btn>
                  </v-flex>
                  <v-flex v-if="totalVehicles <= maxVehicleCountForSelectAll">
                    <v-btn
                      block
                      flat
                      color="primary"
                      class="text-none"
                      data-qa="select-all"
                      @click.stop="selectAllVehicles"
                    >
                      {{
                        $tc('vehicle.toolbar.selectAll', totalVehicles - 1, {
                          numberOfVehiclesToSelect: $n(totalVehicles),
                        })
                      }}
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-slide-y-reverse-transition>
            </v-flex>
          </v-flex>

          <v-flex shrink align-self-right class="pr-2">
            <DataGridColumnSelector
              v-if="displayCustomizeFields"
              :filter-items="allVehicleGridColumns()"
              :label="$t('vehicle.toolbar.customizeFields')"
              store-module="vehicle"
              store-action="setVehicleGridColumns"
              store-getter="vehicleGridColumns"
              :payload-url="vehicleGridColumnUrl()"
            >
            </DataGridColumnSelector>
          </v-flex>
          <v-flex shrink align-self-right>
            <v-menu v-model="showCsvDownloadMenu">
              <template v-slot:activator="{ on }">
                <v-btn
                  v-if="displayExportDataDropdown"
                  outline
                  class="secondary--text text-none export-button"
                  data-qa="export-data-dropdown"
                  v-on="on"
                >
                  <span class="pr-2"
                    ><v-icon small>ic-file-export</v-icon></span
                  >
                  {{ $t('vehicle.toolbar.exportData') }}
                  <v-icon
                    data-qa="export-data-dropdown"
                    class="secondary--text pl-1 align-start"
                    size="23"
                  >
                    ic-down
                  </v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-tile
                  v-for="(item, index) in downloadActions"
                  :key="index"
                  :data-qa="'export-data-dropdown-' + item.dataQa"
                  class="list-item"
                  @click="
                    item.action($event);
                    showCsvDownloadMenu = false;
                  "
                >
                  <v-list-tile-title>{{ item.name }}</v-list-tile-title>
                </v-list-tile>
              </v-list>
            </v-menu>
          </v-flex>
        </v-layout>
      </template>

      <DataGrid
        :pagination="pagination"
        :columns="vehicleColumns"
        :has-drawer="vehicleEditDrawer"
        selectable
        :loading="loading"
        store-module="vehicle"
        row-key="vehicleId"
        :rows-per-page-items="[25, 50, 100]"
        data-qa="vehicles-table"
        :unique-id="uniqueId"
        @open-dialog="setActive"
      >
        <template v-slot:drawer="drawerProps">
          <VehicleEditDialog
            :vehicle-id="drawerProps.data.vehicleId"
            :product-families="productFamilies"
            :show-dialog="drawerProps.dialog"
            :equipment-types="equipmentTypes"
            @vehicle-modified="
              tableUpdate;
              displaySnackBar($event);
            "
            :data-qa="`vehicle-edit-dialog-${drawerProps.data.vehicleId}`"
            class="vehicle-edit-dialog"
            :unique-id="uniqueId"
            :active-element="activeElement"
          />
        </template>
      </DataGrid>
    </DataGridLayout>
    <ConfirmationDialog
      :title="
        $t('vehicle.ccs.title', {
          actionRequiredCount: actionRequiredVehicles.length,
          totalSelected: selectedLength,
        })
      "
      :message="
        $t('vehicle.ccs.message', {
          actionRequiredCount: actionRequiredVehicles.length,
        })
      "
      :data-qa-key="`${uniqueId}-ccs-setting`"
      :enable-dialog="displayCcsDialog"
      :display-confirm="false"
      :cancel-and-continue="true"
      @cancelAndContinue="proceedWithEnrollment"
      persistent
    >
      <template v-slot:link>
        <div>
          <a
            class="download-error-summary secondary--text"
            data-qa="ccs-download-error-summary"
            target="_blank"
            @click="exportErrorSummaryToCsv(getCcsSettings)"
          >
            {{ $t('vehicle-ccs-download-error-summary') }}
          </a>
        </div>
      </template>
      <template v-slot:content>
        <div class="confirmation-dialog-content">
          <div style="flex: 1">
            <v-layout>
              <v-flex>
                <span>
                  {{
                    $t('vehicle.ccs.content', {
                      actionRequiredCount: actionRequiredVehicles.length,
                    })
                  }}
                </span>
              </v-flex>
              <v-flex
                v-if="learnMoreUrl"
                class="body-1"
                data-qa="ccs-learn-more"
                shrink
              >
                <a :href="learnMoreUrl" class="secondary--text" target="_blank">
                  {{ $t('vehicle.ccs.learnmore') }}
                </a>
              </v-flex>
            </v-layout>
          </div>
        </div>
      </template>
    </ConfirmationDialog>
    <ConfirmationDialog
      data-qa="confirmation-dialog-vehicle-unable-to-subscribe-products"
      :title="conflictTitle"
      :message="conflictMessage"
      :enable-dialog="showConfirmWhenConflict"
      :acceptance-text="conflictAcceptanceText"
      :cancellation-text="conflictCancelText"
      :data-qa-key="`${uniqueId}-vehicle-unable-to-subscribe-products`"
      @confirm="handleConflictConfirm"
      @cancel="conflictCancelAction"
      persistent
    >
      <template
        v-if="
          bulkDelete &&
          consentedVehicles.length > 0 &&
          !(enrolledVehicles.length > 0 && processingVehicles.length > 0)
        "
        v-slot:content
      >
        <div
          v-bind:key="vehicle.displayName"
          v-for="vehicle in consentedVehicles"
        >
          <div
            v-if="
              vehicle.consentedConsumers &&
              vehicle.consentedConsumers.length > 0
            "
          >
            <span class="text-left consent-list-item">{{
              vehicle.displayName
            }}</span>
            <span class="text-right consent-list-item">{{
              vehicle.consentedConsumers &&
              vehicle.consentedConsumers.length > 0
                ? vehicle.consentedConsumers[0].name
                : ''
            }}</span>
          </div>
        </div>
      </template>
      <template
        v-else-if="bulkDelete && enrolledVehicles.length > 0"
        v-slot:content
      >
        <p v-bind:key="vehicle.displayName" v-for="vehicle in enrolledVehicles">
          {{ vehicle.displayName }}
        </p>
      </template>
      <template
        v-else-if="
          bulkDelete &&
          notEnrolledVehicles.length > 0 &&
          enrolledVehicles.length === 0
        "
        v-slot:content
      >
        <p
          v-bind:key="vehicle.displayName"
          v-for="vehicle in notEnrolledVehicles"
        >
          {{ vehicle.displayName }}
        </p>
      </template>
      <template v-else v-slot:content>
        <p
          v-bind:key="vehicle.vehicle.vehicleId"
          v-for="vehicle in conflictingVehicles"
        >
          {{ vehicle.vehicle.displayName }}
        </p>
      </template>
    </ConfirmationDialog>
    <ConfirmationDialog
      :data-qa-key="`${uniqueId}-confirmation-dialog-vehicle-remove-consent`"
      :title="conflict2Title"
      :message="conflict2Message"
      :enable-dialog="showConfirmWhenConflict2"
      :acceptance-text="conflict2AcceptanceText"
      :cancellation-text="conflict2CancelText"
      @confirm="handleConflict2Confirm"
      @cancel="conflict2CancelAction"
      :ref="'conflictConfirmationDialog'"
      persistent
    >
      <template
        v-if="bulkDelete && consentedVehicles.length > 0"
        v-slot:content
      >
        <div
          v-bind:key="vehicle.displayName"
          v-for="vehicle in consentedVehicles"
        >
          <div
            v-if="
              vehicle.consentedConsumers &&
              vehicle.consentedConsumers.length > 0
            "
          >
            <span class="text-left consent-list-item">{{
              vehicle.displayName
            }}</span>
            <span class="text-right consent-list-item">{{
              vehicle.consentedConsumers &&
              vehicle.consentedConsumers.length > 0
                ? vehicle.consentedConsumers[0].name
                : ''
            }}</span>
          </div>
        </div>
      </template>
    </ConfirmationDialog>
    <ProcessingDialog
      :show-processing="showProcessing"
      :message="processingMessage"
    ></ProcessingDialog>
    <ErrorDialog
      :error="this.error"
      :show-dialog="showErrorPopup"
      @close-dialog="showErrorPopup = false"
    >
    </ErrorDialog>
  </div>
</template>

<script>
import VehicleService from '@/services/VehicleService';
import ConsentService from '@/services/ConsentService';
import VehicleStatusIcon from '@/components/VehicleStatusIcon';
import DataGridColumnSelector from '@/components/grid/DataGridColumnSelector';
import DataGridSearch from '@/components/grid/DataGridSearch';
import { cloneDeep, isEqual } from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import ConfirmationDialog from '@/components/utility-components/dialogs/ConfirmationDialog';
import ConfirmationDialogWithDropDown from '@/components/utility-components/dialogs/ConfirmationDialogWithDropDown';
import VehicleFilterTotal from '@/components/VehicleFilterTotal';
import SettingService from '@/services/AccountApiSettingsService';
import GroupService from '@/services/GroupService';
import FilterButton from '@/components/FilterButton';
import dashify from 'dashify';
import ProcessingDialog from '@/components/ProcessingDialog';
import ErrorMessageService from '@/services/ErrorMessageService';
import ErrorDialog from '@/components/utility-components/dialogs/ErrorDialog';
import { AccountConstants } from '@/constants';
import VehicleDialog from '@/components/VehicleDialog';
import VehicleBulkModifyDialog from '@/components/VehicleBulkModifyDialog';
import DataGridLayout from '@/components/grid/DataGridLayout';
import DataGridItemTooltip from '@/components/grid/DataGridItemTooltip';
import DataGrid from '@/components/grid/DataGrid';
import DataGridColumn from '@/components/grid/DataGridColumn';
import VehicleFilter from '@/components/VehicleFilter';
import VehicleEditDialog from '@/components/VehicleEditDialog';
import { stringify } from 'query-string';
import DataGridActionButton from '@/components/grid/DataGridActionButton';
import SubscribeToStore from '@/mixins/SubscribeToStore';
import { verifyStaticAssetUrl } from '@/urlUtils';
import convertToCsvFromJson from 'json-to-csv-export';
import EquipmentService from '@/services/EquipmentService';
import { addRowLabelsToVehiclesResults } from '@/dataGridUtils';
import {
  getFileNameFromResponseHeader,
  handleDownloadBlob,
} from '@/services/Downloads';
import FleetFiltersService from '@/services/FleetFiltersService';

export default {
  name: 'MyFleet',
  mixins: [SubscribeToStore('subscribeCallback')],
  async mounted() {
    if (!this.didMount) {
      this.vehicleFilters.products = this.vehicleFilter.products;
      this.toggleExternalFilterChip = this.hasExternalFilterDisplay;
    }
    await this.getVisibleColumns();
    await this.getProducts();
    await this.getUserGroups();
    if (this.canLinkEquipment()) {
      await this.getEquipmentTypes();
    }
    this.setInitialFilterFlags();
    this.didMount = true;
  },
  components: {
    // eslint-disable-next-line vue/no-unused-components
    VehicleFilter,
    VehicleFilterTotal,
    VehicleEditDialog,
    DataGrid,
    DataGridLayout,
    // eslint-disable-next-line vue/no-unused-components
    DataGridActionButton,
    // eslint-disable-next-line vue/no-unused-components
    DataGridItemTooltip,
    ConfirmationDialog,
    ConfirmationDialogWithDropDown,
    DataGridSearch,
    VehicleStatusIcon,
    FilterButton,
    ProcessingDialog,
    ErrorDialog,
    VehicleDialog,
    VehicleBulkModifyDialog,
    DataGridColumnSelector,
  },
  props: {
    visibleColumns: Array,
    displayCustomizeFields: {
      type: Boolean,
      default: true,
    },
    displayAddVehicle: {
      type: Boolean,
      default: true,
    },
    displayExportDataDropdown: {
      type: Boolean,
      default: true,
    },
    displayFilterButtons: {
      type: Boolean,
      default: true,
    },
    displayBulkActionButton: {
      type: Boolean,
      default: true,
    },
    vehicleEditDrawer: {
      type: Boolean,
      default: true,
    },
    customFilter: {
      required: false,
      default: null,
    },
    displayCloseChip: {
      type: Boolean,
      default: true,
    },
    uniqueId: {
      type: String,
      default: 'my-fleet',
    },
  },
  data: () => {
    return {
      displayCcsDialog: false,
      learnMoreUrl: '',
      actionRequiredVehicles: [],
      loading: false,
      didMount: false,
      showMenu: false,
      showCsvDownloadMenu: false,
      showProcessing: false,
      vehicleFilters: {
        makes: [],
        models: [],
        years: [],
        products: [],
        groups: [],
        fuelTypes: [],
        hardwareCategories: [],
      },
      conflictingVehicles: [],
      goodVehicles: [],
      selectedProduct: '',
      showConfirmWhenConflict: false,
      showConfirmWhenConflict2: false,
      productFamilies: [],
      productsInProductFamilies: [],
      userGroups: [],
      availableGroups: [],
      subscribe: false,
      bulkDelete: false,
      actionRequiredCount: '...',
      processingCount: '...',
      subscribedCount: '...',
      showAllActionRequired: false,
      showAllProcessing: false,
      showAllSubscribed: false,
      actionBtnSelectedColor: null,
      processingBtnSelectedColor: null,
      subscribedBtnSelectedColor: null,
      processingMessage: '',
      enrolledVehicles: {},
      notEnrolledVehicles: {},
      processingVehicles: {},
      consentedVehicles: {},
      error: {},
      equipmentTypes: [],
      showErrorPopup: false,
      confirmationDialog: {
        confirmationTitle: '',
        confirmationMessage: '',
        acceptanceText: '',
        cancelText: '',
        displayConfirm: false,
        dataQa: '',
      },
      oldFilter: null,
      oldSearch: null,
      updateAggregates: true,
      pagination: {},
      externalFilterChip: true,
      toggleExternalFilterChip: false,
      ccsSetting: {},
      selectedProductFamilyId: null,
      activeElement: null,
    };
  },
  watch: {
    externalFilterChip: function () {
      if (!this.externalFilterChip) {
        this.clearExternalFilterDisplay();
      }
    },
  },
  computed: {
    ...mapGetters('auth', [
      'canAddVehicle',
      'canViewMarkForDelete',
      'getLanguage',
      'canLinkEquipment',
    ]),
    ...mapGetters('vehicle', [
      'paginationQuery',
      'searchQuery',
      'vehicleFilter',
      'selectedLength',
      'selected',
      'totalVehicles',
      'totalFilteredVehicles',
      'vehicleGridColumns',
      'hasColumn',
      'hasExternalFilterDisplay',
      'getCcsSettings',
    ]),
    ...mapGetters('configuration', [
      'maxVehicleCountForSelectAll',
      'fordTelematicsProductFamilyId',
      'retrieveVehicleFiltersFromServices',
    ]),
    activeBulkActions() {
      return this.bulkActions.filter((e) => e.display === true);
    },
    bulkActions() {
      return [
        {
          name: this.$t('vehicle.toolbar.subscribeProducts.name'),
          title: this.$t('vehicle.toolbar.subscribeProducts.title'),
          message: this.$t('vehicle.toolbar.subscribeProducts.message', {
            nVehicles: this.$tc('vehicle.nVehicles', this.selectedLength, {
              numberOfVehicles: this.selectedLength,
            }),
          }),
          acceptanceText: this.$t(
            'vehicle.toolbar.subscribeProducts.acceptanceText'
          ),
          cancellationText: this.$t('confirmationDialog.cancel'),
          dialog: 'confirmationDialogWithDropDown',
          label: this.$t('vehicle.toolbar.subscribeProducts.label'),
          action: this.attemptSubscribe,
          getEnrolledProductFamilies: false,
          isChurnRequired: false,
          deleteAction: false,
          dataQa: 'vehicle-subscribe-products',
          display: true,
          isMultiSelect: false,
        },
        {
          name: this.$t('vehicle.toolbar.unsubscribeProducts.name'),
          title: this.$t('vehicle.toolbar.unsubscribeProducts.title'),
          message: this.productUnsubscribeMessage,
          acceptanceText: this.$t(
            'vehicle.toolbar.unsubscribeProducts.acceptanceText'
          ),
          cancellationText: this.$t('confirmationDialog.cancel'),
          dialog: 'confirmationDialogWithDropDown',
          label:
            this.productsInProductFamilies.length > 0
              ? this.$t('vehicle.toolbar.unsubscribeProducts.label')
              : this.$t('vehicle.toolbar.unsubscribeProducts.labelNoProducts'),
          action: this.attemptUnsubscribe,
          getEnrolledProductFamilies: true,
          isChurnRequired: true,
          deleteAction: false,
          dataQa: 'vehicle-unsubscribe-products',
          display: true,
          isMultiSelect: false,
        },
        {
          name: this.$t('vehicle.toolbar.bulkModify.name'),
          dialog: 'vehicleBulkModifyDialog',
          dataQa: 'vehicle-bulk-modify',
          display: true,
          isMultiSelect: false,
        },
        {
          name: this.$t('vehicle.toolbar.addToGroup.name'),
          title: this.$t('vehicle.toolbar.addToGroup.title'),
          message: this.$t('vehicle.toolbar.addToGroup.message', {
            nVehicles: this.$tc('vehicle.nVehicles', this.selectedLength, {
              numberOfVehicles: this.selectedLength,
            }),
          }),
          acceptanceText: this.$t('vehicle.toolbar.addToGroup.acceptanceText'),
          cancellationText: this.$t('confirmationDialog.cancel'),
          dialog: 'confirmationDialogWithDropDownForGroups',
          label: this.$t('vehicle.toolbar.addToGroup.label'),
          action: this.addToGroup,
          deleteAction: false,
          dataQa: 'add-to-group',
          rule: this.addGroupRule,
          isRemove: false,
          display: true,
          isMultiSelect: true,
        },
        {
          name: this.$t('vehicle.toolbar.removeFromGroup.name'),
          title: this.$t('vehicle.toolbar.removeFromGroup.title'),
          message: this.$t('vehicle.toolbar.removeFromGroup.message', {
            nVehicles: this.$tc('vehicle.nVehicles', this.selectedLength, {
              numberOfVehicles: this.selectedLength,
            }),
          }),
          acceptanceText: this.$t(
            'vehicle.toolbar.removeFromGroup.acceptanceText'
          ),
          cancellationText: this.$t('confirmationDialog.cancel'),
          dialog: 'confirmationDialogWithDropDownForGroups',
          label: this.$t('vehicle.toolbar.removeFromGroup.label'),
          action: this.removeFromGroup,
          deleteAction: false,
          dataQa: 'remove-from-group',
          rule: this.removeGroupRule,
          isRemove: true,
          display: true,
          isMultiSelect: true,
        },
        {
          name: this.$t('vehicle.toolbar.decommission.name'),
          title: this.$t('vehicle.toolbar.decommission.title'),
          message: this.$t('vehicle.toolbar.decommission.message', {
            nVehicles: this.$tc('vehicle.nVehicles', this.selectedLength, {
              numberOfVehicles: this.selectedLength,
            }),
          }),
          acceptanceText: this.$t(
            'vehicle.toolbar.decommission.acceptanceText'
          ),
          cancellationText: this.$t('confirmationDialog.cancel'),
          dialog: 'confirmationDialog',
          label:
            this.productsInProductFamilies.length > 0
              ? this.$t('vehicle.toolbar.decommission.label')
              : this.$t('vehicle.toolbar.decommission.labelNoProducts'),
          action: this.determineBulkDeleteConflict,
          getEnrolledProductFamilies: false,
          deleteAction: true,
          dataQa: 'mark-deletion',
          display: this.canViewMarkForDelete(),
          isMultiSelect: false,
        },
      ];
    },
    downloadActions() {
      return [
        {
          name: this.$t('vehicle.toolbar.exportAll'),
          dataQa: 'export-all',
          action: this.downloadVehiclesCsv,
        },
        {
          name: this.$t('vehicle.toolbar.exportCurrentFilters'),
          dataQa: 'export-filters',
          action: this.downloadVehiclesCsvWithFilter,
        },
      ];
    },
    itemRule() {
      return [
        (v) =>
          !!v ||
          this.$t('vehicle.toolbar.subscribeProducts.rules.required', {
            rule: this.$t('vehicle.toolbar.subscribeProducts.productRule'),
          }),
      ];
    },
    addGroupRule() {
      return [
        (v) =>
          !!v ||
          this.$t('vehicle.toolbar.addToGroup.rules.required', {
            rule: this.$t('vehicle.toolbar.addToGroup.rules.field'),
          }),
      ];
    },
    removeGroupRule() {
      return [
        (v) =>
          !!v ||
          this.$t('vehicle.toolbar.removeFromGroup.rules.required', {
            rule: this.$t('vehicle.toolbar.removeFromGroup.rules.field'),
          }),
      ];
    },
    conflictTitle() {
      if (this.subscribe) {
        return this.$t('vehicle.toolbar.unableToSubscribeProducts.title');
      } else if (this.bulkDelete) {
        if (
          this.consentedVehicles.length > 0 &&
          !(
            this.enrolledVehicles.length > 0 &&
            this.processingVehicles.length > 0
          )
        ) {
          return this.$t('vehicle.toolbar.deconsentAndDecommission.title');
        } else if (this.enrolledVehicles.length > 0) {
          return this.$t(
            'vehicle.editVehicle.decommissionAndUnsubscribeConfirmationTitle'
          );
        } else {
          return this.$t('vehicle.editVehicle.decommissionConfirmationTitle');
        }
      }

      return this.$t('vehicle.toolbar.unableToUnsubscribeProducts.title');
    },
    conflict2Title() {
      return this.$t('vehicle.toolbar.deconsentAndDecommission.title');
    },
    conflict2Message() {
      return this.$t('vehicle.toolbar.deconsentAndDecommission.message', {
        nVehiclesIsare: this.$tc(
          'vehicle.nVehiclesWithAreOrIs',
          this.consentedVehicles.length,
          { numberOfVehicles: this.consentedVehicles.length }
        ),
        nVehicles: this.$tc(
          'vehicle.nVehicles',
          this.consentedVehicles.length,
          { numberOfVehicles: this.consentedVehicles.length }
        ),
      });
    },
    conflictMessage() {
      if (
        this.bulkDelete &&
        this.consentedVehicles.length > 0 &&
        !(
          this.enrolledVehicles.length > 0 && this.processingVehicles.length > 0
        )
      ) {
        return this.$t('vehicle.toolbar.deconsentAndDecommission.message', {
          nVehiclesIsare: this.$tc(
            'vehicle.nVehiclesWithAreOrIs',
            this.consentedVehicles.length,
            { numberOfVehicles: this.consentedVehicles.length }
          ),
          nVehicles: this.$tc(
            'vehicle.nVehicles',
            this.consentedVehicles.length,
            { numberOfVehicles: this.consentedVehicles.length }
          ),
        });
      } else if (this.bulkDelete && this.enrolledVehicles.length > 0) {
        return this.$t(
          'vehicle.editVehicle.bulkDecommissionAndUnsubscribeConfirmationMessage',
          {
            vehicleOrVehicles: this.$tc(
              'vehicle.editVehicle.vehicleOrVehicles',
              this.enrolledVehicles.length,
              { numVehicle: this.enrolledVehicles.length }
            ),
          }
        );
      } else if (this.bulkDelete && this.notEnrolledVehicles.length > 0) {
        return this.$t(
          'vehicle.editVehicle.bulkDecommissionConfirmationMessage',
          {
            deleteVehicles: this.$tc(
              'vehicle.editVehicle.deleteVehicles',
              this.notEnrolledVehicles.length,
              { numVehicle: this.notEnrolledVehicles.length }
            ),
          }
        );
      } else {
        if (this.goodVehicles.length > 0) {
          if (this.subscribe) {
            return this.$t(
              'vehicle.toolbar.unableToSubscribeProducts.message',
              {
                nBadVehicles: this.$tc(
                  'vehicle.nVehiclesWithAreOrIs',
                  this.conflictingVehicles.length,
                  { numberOfVehicles: this.conflictingVehicles.length }
                ),
                nGoodVehicles: this.$tc(
                  'vehicle.numberOfVehicles',
                  this.goodVehicles.length,
                  { numberOfVehicles: this.goodVehicles.length }
                ),
              }
            );
          }
          return this.$t(
            'vehicle.toolbar.unableToUnsubscribeProducts.message',
            {
              nBadVehicles: this.$tc(
                'vehicle.nVehiclesWithAreOrIs',
                this.conflictingVehicles.length,
                { numberOfVehicles: this.conflictingVehicles.length }
              ),
              nGoodVehicles: this.$tc(
                'vehicle.numberOfVehicles',
                this.goodVehicles.length,
                { numberOfVehicles: this.goodVehicles.length }
              ),
            }
          );
        }

        return this.$t(
          `vehicle.toolbar.unableToSubscribeProducts.${
            this.conflictingVehicles.length > 1
              ? 'noVehiclesMessage'
              : 'noVehiclesMessageSingular'
          }`,
          {
            count: this.conflictingVehicles.length,
          }
        );
      }
    },
    conflict2AcceptanceText() {
      return this.$t('vehicle.toolbar.deconsentAndDecommission.acceptanceText');
    },
    conflictAcceptanceText() {
      if (this.bulkDelete) {
        if (
          this.consentedVehicles.length > 0 &&
          !(
            this.enrolledVehicles.length > 0 &&
            this.processingVehicles.length > 0
          )
        ) {
          return this.$t(
            'vehicle.toolbar.deconsentAndDecommission.acceptanceText'
          );
        }
        if (this.enrolledVehicles.length > 0) {
          return this.$t('vehicle.editVehicle.decommissionAndUnsubscribe');
        } else {
          return this.$t('vehicle.editVehicle.decommission');
        }
      } else {
        if (this.goodVehicles.length > 0) {
          if (this.subscribe) {
            return this.$t(
              'vehicle.toolbar.unableToSubscribeProducts.acceptanceText'
            );
          }
          return this.$t(
            'vehicle.toolbar.unableToUnsubscribeProducts.acceptanceText'
          );
        } else {
          return this.$t('vehicle.toolbar.unableToSubscribeProducts.exit');
        }
      }
    },
    conflict2CancelText() {
      return this.$t('confirmationDialog.goBack');
    },
    conflictCancelText() {
      if (this.bulkDelete) {
        if (
          this.consentedVehicles.length > 0 &&
          !(
            this.enrolledVehicles.length > 0 &&
            this.processingVehicles.length > 0
          )
        ) {
          return this.$t('confirmationDialog.goBack');
        } else {
          return this.$t('confirmationDialog.cancel');
        }
      } else {
        return this.$t('confirmationDialog.goBack');
      }
    },
    vehicleColumns() {
      return [
        [
          this.$t('vehicle.grid.headers.status'),
          'status',
          {
            displayField: this.hasColumn('Status'),
            itemActionRender: function (h) {
              return h('div', [
                h(VehicleStatusIcon, {
                  props: {
                    status: this.$attrs.item['enrollmentStatus'],
                    displayStatusText: false,
                  },
                }),
              ]);
            },
          },
        ],
        [
          this.$t('vehicle.grid.headers.name'),
          'name',
          {
            sortable: true,
            displayField: this.hasColumn('ID'),
          },
        ],
        [
          this.$t('vehicle.grid.headers.vin'),
          'vin',
          {
            sortable: true,
            displayField: this.hasColumn('VIN'),
          },
        ],
        [
          this.$t('vehicle.grid.headers.licensePlate'),
          'licensePlate',
          {
            displayField: this.hasColumn('License Plate'),
            sortable: true,
            itemActionRender: function (h) {
              return h(
                'span',
                this.$attrs.item.licensePlate &&
                  this.$attrs.item.licensePlate !== ''
                  ? this.$attrs.item.licensePlate
                  : '-'
              );
            },
            hideItem: true,
          },
        ],
        [
          this.$t('vehicle.grid.headers.licensePlateExpirationDate'),
          'licensePlateExpirationDate',
          {
            displayField: this.hasColumn('License Expiration'),
            sortable: true,
            itemActionRender: function (h) {
              return h(
                'span',
                this.$attrs.item.licensePlateExpirationDate &&
                  this.$attrs.item.licensePlateExpirationDate !== ''
                  ? ''
                  : '-'
              );
            },
            isDateField: true,
          },
        ],
        [
          this.$t('vehicle.grid.headers.licensePlateState'),
          'licensePlateState',
          {
            displayField: this.hasColumn('State'),
            sortable: true,
            headerFilterRender: (h) => {
              return h(VehicleFilter, {
                props: {
                  filterItems: this.vehicleFilters.licensePlateStates,
                  filterName: 'licensePlateStates',
                },
              });
            },
          },
        ],
        [
          this.$t('vehicle.grid.headers.products'),
          'products',
          {
            displayField: this.hasColumn('Products'),
            headerFilterRender: (h) => {
              return h(VehicleFilter, {
                props: {
                  filterItems: this.vehicleFilters.products,
                  filterName: 'products',
                  disabled: this.toggleExternalFilterChip,
                },
              });
            },
            sortable: false,
            itemActionRender: function (h) {
              return h('div', [
                h(
                  'span',
                  this.$attrs.item.products &&
                    this.$attrs.item.products.length > 0
                    ? this.$attrs.item.products.length
                    : '-' + ' '
                ),
                h(DataGridItemTooltip, {
                  props: {
                    show:
                      this.$attrs.item.products &&
                      this.$attrs.item.products.length > 0,
                    name: 'products',
                    items: this.$attrs.item.products,
                  },
                }),
              ]);
            },
            hideItem: true,
          },
        ],
        [
          this.$t('vehicle.grid.headers.groups'),
          'groups',
          {
            displayField: this.hasColumn('Groups'),
            headerFilterRender: (h) => {
              return h(VehicleFilter, {
                props: {
                  filterItems: this.vehicleFilters.groups,
                  filterName: 'groups',
                  keyName: 'id',
                  valueName: 'name',
                },
              });
            },
            itemActionRender: function (h) {
              return h('div', [
                h('span', this.$attrs.item.groups.length + ' '),
                h(DataGridItemTooltip, {
                  props: {
                    show: true,
                    name: 'groups',
                    items: this.$attrs.item.groups,
                  },
                }),
              ]);
            },
            hideItem: true,
            sortable: false,
          },
        ],
        [
          this.$t('vehicle.grid.headers.make'),
          'make',
          {
            displayField: this.hasColumn('Make'),
            sortable: true,
            headerFilterRender: (h) => {
              return h(VehicleFilter, {
                props: {
                  filterItems: this.vehicleFilters.makes,
                  filterName: 'makes',
                },
              });
            },
          },
        ],
        [
          this.$t('vehicle.grid.headers.model'),
          'model',
          {
            displayField: this.hasColumn('Model'),
            sortable: true,
            headerFilterRender: (h) => {
              return h(VehicleFilter, {
                props: {
                  filterItems: this.vehicleFilters.models,
                  filterName: 'models',
                },
              });
            },
          },
        ],
        [
          this.$t('vehicle.grid.headers.year'),
          'year',
          {
            displayField: this.hasColumn('Year'),
            filterName: 'years',
            sortable: true,
            headerFilterRender: (h) => {
              return h(VehicleFilter, {
                props: {
                  filterItems: this.vehicleFilters.years,
                  filterName: 'years',
                },
              });
            },
          },
        ],
        [
          this.$t('vehicle.grid.headers.hardware'),
          'hardwareCategory',
          {
            displayField: this.hasColumn('Hardware'),
            sortable: false,
            hideItem: true,
            itemActionRender: function (h) {
              return h('div', [
                h(
                  'span',
                  this.$attrs.item.hardwareCategory &&
                    this.$attrs.item.hardwareCategory !== ''
                    ? this.$t(this.$attrs.item.hardwareCategory)
                    : '-'
                ),
              ]);
            },
            headerFilterRender: (h) => {
              return h(VehicleFilter, {
                props: {
                  filterItems: this.vehicleFilters.hardwareCategories,
                  filterName: 'hardwareCategories',
                  translate: true,
                },
              });
            },
          },
        ],
        [
          this.$t('vehicle.grid.headers.fuelType'),
          'fuelType',
          {
            displayField: this.hasColumn('Fuel'),
            filterName: 'fuelTypes',
            sortable: true,
            internationalize: true,
            internationalizeRoot: 'vehicle.fuelTypes.',
            headerFilterRender: (h) => {
              return h(VehicleFilter, {
                props: {
                  filterItems: this.vehicleFilters.fuelTypes,
                  filterName: 'fuelTypes',
                  translate: true,
                  translateRoot: 'vehicle.fuelTypes.',
                },
              });
            },
          },
        ],
        [
          this.$t('vehicle.grid.headers.actions'),
          'actions',
          {
            displayField: this.hasColumn('Actions'),
            itemActionRender: function (h) {
              return h(DataGridActionButton, {
                props: {
                  flat: true,
                  buttonText: this.$t('vehicle.edit'),
                  item: this.$attrs.item,
                  rowKey: 'vehicleId',
                  dataQaPrefix: 'vehicle-edit-button-',
                },
              });
            },
          },
        ],
      ].map((col) => {
        return DataGridColumn.apply(null, col);
      });
    },
    selectedServiceProviders() {
      return Object.values(this.consentedVehicles).reduce(
        (accumulator, currentVehicle) => {
          currentVehicle.consentedConsumers.forEach((currentConsumer) => {
            accumulator[currentConsumer.id] = currentConsumer;
          });
          return accumulator;
        },
        {}
      );
    },
    productUnsubscribeMessage() {
      let defaultMessage = this.$t(
        'vehicle.toolbar.unsubscribeProducts.message',
        {
          nVehicles: this.$tc('vehicle.nVehicles', this.selectedLength, {
            numberOfVehicles: this.selectedLength,
          }),
        }
      );
      let unlinkMessage = this.$tc(
        'vehicle.toolbar.unsubscribeProducts.unlinkMessage',
        this.selectedLength
      );
      return this.selectedProductFamilyId === this.fordTelematicsProductFamilyId
        ? defaultMessage + unlinkMessage
        : defaultMessage;
    },
  },
  methods: {
    async getEquipmentTypes() {
      return EquipmentService.getEquipmentTypes()
        .then((response) => {
          this.equipmentTypes = response.data;
        })
        .catch((err) => {
          this.handleError(err);
        });
    },
    setActive(active) {
      this.activeElement = active;
    },
    setSelectedProductFamilyId($event) {
      let product = this.productsInProductFamilies.find((p) => p.id === $event);
      this.selectedProductFamilyId =
        product !== null && product !== undefined
          ? product.productFamilyId
          : null;
    },
    subscribeCallback(mutation) {
      if (
        mutation.type === 'vehicle/SET_VEHICLE_FILTER' ||
        mutation.type === 'vehicle/SET_SEARCH_QUERY' ||
        mutation.type === 'vehicle/SET_PAGINATION_QUERY' ||
        mutation.type === 'vehicle/TOGGLE_VEHICLE_FILTER' ||
        (this.didMount && mutation.type === 'vehicle/CLEAR_FILTER_AND_SEARCH')
      ) {
        this.getVehicles();
        this.getVehicleFilters();
        this.toggleExternalFilterChip = this.hasExternalFilterDisplay;
        if (mutation.type === 'vehicle/CLEAR_FILTER_AND_SEARCH') {
          if (this.showAllActionRequired) {
            this.toggleButton(
              'showAllActionRequired',
              'actionBtnSelectedColor'
            );
          }
          if (this.showAllProcessing) {
            this.toggleButton(
              'showAllProcessing',
              'processingBtnSelectedColor'
            );
          }
          if (this.showAllSubscribed) {
            this.toggleButton(
              'showAllSubscribed',
              'subscribedBtnSelectedColor'
            );
          }
        }
      }
    },
    getAvailableGroups(isRemove) {
      if (isRemove) {
        GroupService.getVehicleBasicGroups(Object.keys(this.selected))
          .then((res) => {
            this.availableGroups = res.data;
          })
          .catch((err) => console.log(err));
      } else {
        this.availableGroups = this.userGroups;
      }
    },
    isBulkActionDisabled(index) {
      return index === 2 && this.selectedLength < 2;
    },
    handleConflictConfirm() {
      if (this.bulkDelete) {
        if (
          this.consentedVehicles.length > 0 &&
          this.processingVehicles.length > 0 &&
          this.enrolledVehicles.length > 0
        ) {
          this.showConfirmWhenConflict = false;
          this.showConfirmWhenConflict2 = true;
        } else {
          this.showConfirmWhenConflict = false;
          this.bulkDeconsentAndDisable();
        }
      } else {
        this.showConfirmWhenConflict = false;
        if (this.goodVehicles.length > 0) {
          if (this.subscribe) {
            this.checkCcsSettings();
            if (!this.displayCcsDialog) {
              this.subscribeVehiclesToProduct(
                this.selectedProduct,
                this.goodVehicles
              );
            }
          } else {
            this.unsubscribeVehiclesFromProduct(
              this.selectedProduct,
              this.goodVehicles
            );
          }
        }
      }
    },
    handleConflict2Confirm() {
      if (this.bulkDelete) {
        this.bulkDeconsentAndDisable();
      }
    },
    conflictCancelAction() {
      this.showConfirmWhenConflict = false;
      if (
        !this.bulkDelete ||
        (this.bulkDelete &&
          this.consentedVehicles.length > 0 &&
          !(
            this.enrolledVehicles.length > 0 &&
            this.processingVehicles.length > 0
          ))
      ) {
        this.reshowProductDialog();
      } else {
        this.clearBulkDeleteObjects();
        this.bulkDelete = false;
      }
    },
    conflict2CancelAction() {
      this.showConfirmWhenConflict2 = false;
      this.showConfirmWhenConflict = true;
    },
    clearBulkDeleteObjects() {
      this.enrolledVehicles = {};
      this.notEnrolledVehicles = {};
      this.processingVehicles = {};
      this.consentedVehicles = {};
      this.confirmationDialog = {
        confirmationTitle: '',
        confirmationMessage: '',
        acceptanceText: '',
        cancelText: '',
        displayConfirm: false,
        dataQa: '',
      };
      this.showConfirmWhenConflict2 = false;
      this.showConfirmWhenConflict = false;
    },
    async getProducts() {
      this.productFamilies =
        await this.fetchCompanyVehicleLevelProductFamilies();
    },
    getVisibleColumns() {
      if (this.visibleColumns) {
        this.setVehicleGridColumns(
          this.visibleColumns.map((code) => {
            return {
              code,
              name: this.$t(
                AccountConstants.ALL_VEHICLE_GRID_COLUMNS.find(
                  (column) => column.code === code
                ).name
              ),
            };
          })
        );
      } else {
        SettingService.getSetting(AccountConstants.VEHICLE_GRID_COLUMNS_URL)
          .then((res) => {
            let filteredValues = this.allVehicleGridColumns().filter((v) =>
              res.data.includes(v.code)
            );
            this.setVehicleGridColumns(filteredValues);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    getUserGroups() {
      GroupService.getBasicGroups()
        .then((res) => {
          this.userGroups = res.data;
        })
        .catch((err) => console.log(err));
    },
    getProductsFromProductFamilies(productFamilies, filterRFIDConfigurable) {
      let resultArray = [];
      productFamilies.forEach((productFamily) => {
        productFamily.products.forEach((product) => {
          if (!filterRFIDConfigurable || !product.rfidConfigurable) {
            resultArray.push({
              name:
                this.$tf(
                  'productFamily.' + productFamily.id,
                  productFamily.name
                ) +
                ' - ' +
                product.name,
              id: product.sku,
              productFamilyId: productFamily.id,
            });
          }
        });
      });
      return resultArray;
    },
    getProductFamilies(getProductFamilies) {
      if (getProductFamilies) {
        VehicleService.getVehicleEnrolledProducts(Object.keys(this.selected))
          .then((res) => {
            this.productsInProductFamilies =
              this.getProductsFromProductFamilies(res.data, false);
          })
          .catch((err) => console.log(err));
      } else {
        let vins = [];
        Object.keys(this.selected).forEach((id) =>
          vins.push(this.selected[id].vinNumber)
        );
        VehicleService.getCcsSettings(vins)
          .then((res) => {
            this.setCcsSettings(res.data);
          })
          .catch((err) => console.log(err));
        this.productsInProductFamilies = this.getProductsFromProductFamilies(
          this.productFamilies,
          true
        );
      }
    },
    getEnrollmentAggregates() {
      this.oldSearch = this.searchQuery;
      this.oldFilter = cloneDeep(this.vehicleFilter);
      this.actionRequiredCount = '...';
      this.processingCount = '...';
      this.subscribedCount = '...';
      VehicleService.getEnrollmentAggregatesRouter(
        stringify(this.paginationQuery),
        this.vehicleFilter,
        this.searchQuery
      ).then((res) => {
        let responseData = res.data;
        this.actionRequiredCount = responseData.actionRequired;
        this.processingCount = responseData.processing;
        this.subscribedCount = responseData.subscribed;
      });
    },
    getVehicles() {
      this.loading = true;
      let filter = { ...this.vehicleFilter, filterHiddenProducts: true };
      VehicleService.getConsentedVehiclesRouter(
        this.paginationQuery,
        filter,
        this.searchQuery,
        this.isUpgrade
      )
        .then((res) => {
          this.loading = false;
          this.pagination = addRowLabelsToVehiclesResults(res.data);

          this.setTotalVehicles(res.data.totalResultSize);
          this.setTotalFilteredVehicles(res.data.resultSize);
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
      if (
        !isEqual(this.oldFilter, this.vehicleFilter) ||
        !isEqual(this.oldSearch, this.searchQuery)
      ) {
        this.getEnrollmentAggregates();
      }
    },
    handleCsvDownloadResponse(response) {
      const blob = new Blob(
        [decodeURIComponent(encodeURIComponent(response.data))],
        { type: 'text/csv' }
      );
      const filename = getFileNameFromResponseHeader(
        response.headers['content-disposition']
      );
      handleDownloadBlob(blob, filename);
    },
    downloadVehiclesCsv() {
      VehicleService.downloadVehiclesCsv()
        .then((response) => this.handleCsvDownloadResponse(response))
        .catch((err) => {
          console.log(err);
        });
    },
    downloadVehiclesCsvWithFilter() {
      VehicleService.downloadVehiclesCsvWithFilter(
        this.vehicleFilter,
        this.searchQuery
      )
        .then((response) => this.handleCsvDownloadResponse(response))
        .catch((err) => {
          console.log(err);
        });
    },
    selectAllVehicles() {
      VehicleService.getBasicConsentedVehicles(
        this.vehicleFilter,
        this.searchQuery
      )
        .then((res) => {
          this.addSelected({ data: res.data, key: 'vehicleId' });
        })
        .catch((err) => console.log(err));
    },
    getVehicleFilters() {
      (this.retrieveVehicleFiltersFromServices
        ? FleetFiltersService.getFleetTableFilters()
        : VehicleService.getConsentedVehicleFiltersOld()
      )
        .then((res) => {
          this.vehicleFilters = cloneDeep(res.data);
        })
        .catch((err) => console.log(err));
    },
    attemptSubscribe(productSku) {
      this.showProcessing = true;
      let vehicles = Object.keys(this.selected);
      this.processingMessage = this.$tc(
        'vehicle.processingBar.subscribeMessage',
        vehicles.length,
        { count: vehicles.length }
      );
      VehicleService.getEnrollmentStatus(productSku.id, vehicles)
        .then((res) => {
          let responseVehicles = res.data;
          let badVehicles = responseVehicles.filter((value) => {
            return value.capable === false;
          });
          let goodVehicles = responseVehicles.filter((value) => {
            return value.capable;
          });
          if (goodVehicles.length > 0 && badVehicles.length === 0) {
            this.showProcessing = false;
            this.checkCcsSettings();
            if (!this.displayCcsDialog) {
              this.subscribeVehiclesToProduct(productSku, goodVehicles);
            } else {
              this.selectedProduct = productSku;
              this.goodVehicles = goodVehicles;
            }
          } else {
            this.selectedProduct = productSku;
            this.goodVehicles = goodVehicles;
            this.subscribe = true;
            this.conflictingVehicles = badVehicles;
            this.showConfirmWhenConflict = true;
            this.showProcessing = false;
          }
        })
        .catch((error) => {
          console.log(error);
          this.showProcessing = false;
        });
    },
    async checkCcsSettings() {
      this.getCcsSettings.forEach((setting) => {
        if (
          setting.actionRequired &&
          AccountConstants.VEHICLE_STATE_CATEGORY.includes(
            setting.actionCategory
          )
        ) {
          this.actionRequiredVehicles.push(setting);
          this.displayCcsDialog = true;
        }
      });
      if (this.displayCcsDialog) {
        this.learnMoreUrl = await verifyStaticAssetUrl(
          '/learnmore-ccs/' + this.getLanguage + '.pdf'
        );
      }
    },
    proceedWithEnrollment() {
      this.displayCcsDialog = false;
      this.subscribeVehiclesToProduct(this.selectedProduct, this.goodVehicles);
    },
    getEnrollmentStatus(productSku, vehicles) {
      VehicleService.getEnrollmentStatus(productSku, vehicles);
    },
    bulkDeconsentAndDisable(data) {
      let vehicleIds = Object.keys(this.selected);
      let promiseArray = Object.values(this.selectedServiceProviders).map(
        (serviceProvider) =>
          this.getDeconsentVehiclesPromise(serviceProvider, vehicleIds)
      );
      return Promise.all(promiseArray)
        .then((values) =>
          values.find((value) => value.status === 'rejected')
            ? Promise.reject(values)
            : values
        )
        .then(() => this.bulkMarkForDeletion(data))
        .catch((error) => {
          console.log(error);
          this.displayErrorSnackBar(
            this.$t(
              'vehicle.toolbar.deconsentAndDecommission.snackBarMessage.decommission.endError'
            )
          );
        });
    },
    bulkMarkForDeletion(data) {
      let message;
      let finalBulkDeleteArray = this.enrolledVehicles
        .map((e) => e.vehicleId)
        .concat(this.notEnrolledVehicles.map((u) => u.vehicleId));
      if (finalBulkDeleteArray.length > 0) {
        return VehicleService.bulkDisableVehiclesV2(finalBulkDeleteArray, data)
          .then(() => {
            this.updateAggregates = false;
            let vehiclesCountsChanged = false;
            if (this.enrolledVehicles.length > 0) {
              vehiclesCountsChanged = true;
              message = this.$t(
                'vehicle.editVehicle.snackBar.successMessageDecommissionMultipleWithProduct',
                {
                  deleteVehicles: this.$tc(
                    'vehicle.editVehicle.deleteVehiclesWithDisplayName',
                    this.enrolledVehicles.length,
                    {
                      numVehicle: this.enrolledVehicles.length,
                      displayName: this.enrolledVehicles[0].displayName,
                    }
                  ),
                }
              );
              this.displaySnackBar(message);
            }
            if (this.notEnrolledVehicles.length > 0) {
              vehiclesCountsChanged = true;
              message = this.$tc(
                'vehicle.editVehicle.snackBar.successMessageDecommissionMultipleWithoutProduct',
                this.notEnrolledVehicles.length,
                {
                  deleteVehicles: this.$tc(
                    'vehicle.editVehicle.deleteVehiclesWithDisplayName',
                    this.notEnrolledVehicles.length,
                    {
                      numVehicle: this.notEnrolledVehicles.length,
                      displayName: this.notEnrolledVehicles[0].displayName,
                    }
                  ),
                }
              );
              this.displaySnackBar(message);
            }
            this.updateAggregates = true;
            if (vehiclesCountsChanged)
              this.getEnrollmentAggregates(this.paginationQuery);
            this.clearBulkDeleteObjects();
            this.clearSelected();
          })
          .catch((err) => {
            console.log(err);
            message = this.$t(
              'vehicle.editVehicle.snackBar.errorMessageDecommissionMultiple',
              {
                deleteVehicles: this.$tc(
                  'vehicle.editVehicle.deleteVehicles',
                  finalBulkDeleteArray.length,
                  { numVehicle: finalBulkDeleteArray.length }
                ),
              }
            );
            this.displayErrorSnackBar(message);
            this.clearBulkDeleteObjects();
          });
      }
    },
    getDeconsentVehiclesPromise(serviceProvider, vehicleIds) {
      return ConsentService.bulkDeconsentVehicle({
        vehicleIds,
        consumerId: serviceProvider.id,
      })
        .then((response) => {
          let message = this.$tc(
            'vehicle.toolbar.deconsentAndDecommission.snackBarMessage.deconsent.success',
            response.data,
            {
              count: response.data,
              consumerName: serviceProvider.name,
              vinName: this.selected[Object.keys(this.selected)[0]].vin,
            }
          );
          this.displaySnackBar(message);
          this.tableUpdate();
          return { status: 'resolved', data: response };
        })
        .catch((error) => {
          this.displayErrorSnackBar(
            this.$t(
              'vehicle.toolbar.deconsentAndDecommission.snackBarMessage.deconsent.error',
              { consumerName: serviceProvider.name }
            )
          );
          console.log(error);
          return { status: 'rejected', data: error };
        });
    },
    subscribeVehiclesToProduct(product, vehicles) {
      this.showProcessing = true;
      this.processingMessage = this.$tc(
        'vehicle.processingBar.subscribeMessage',
        vehicles.length,
        { count: vehicles.length }
      );
      VehicleService.subscribeVehiclesToProduct(
        product.id,
        vehicles.map((v) => v.vehicle.vehicleId)
      )
        .then(() => {
          let message = this.$tc(
            'vehicle.toolbar.subscribeProducts.snackBarMessage.success',
            vehicles.length,
            {
              count: vehicles.length,
              productName: product.name,
              vinName: vehicles[0].vehicle.displayName,
            }
          );
          this.displayInformationSnackBar(message);
        })
        .catch((err) => {
          console.log(err);
          this.handleError(err);
        })
        .finally(() => {
          this.clearSelected();
          this.showProcessing = false;
          this.actionRequiredVehicles = [];
        });
    },
    unsubscribeVehiclesFromProduct(product, vehicles) {
      this.showProcessing = true;
      this.processingMessage = this.$tc(
        'vehicle.processingBar.unsubscribeMessage',
        vehicles.length,
        { count: vehicles.length }
      );
      VehicleService.unenrollVehicleProduct(
        product.id,
        vehicles.map((v) => v.vehicle.vehicleId)
      )
        .then(() => {
          let message = this.$tc(
            'vehicle.toolbar.unsubscribeProducts.snackBarMessage.success',
            vehicles.length,
            {
              count: vehicles.length,
              productName: product.name,
              vinName: vehicles[0].vehicle.displayName,
            }
          );
          this.displayInformationSnackBar(message);
          this.clearSelected();
          this.showProcessing = false;
        })
        .catch((err) => {
          console.log(err);
          let message = this.$tc(
            'vehicle.toolbar.unsubscribeProducts.snackBarMessage.error',
            this.selectedLength,
            {
              count: this.selectedLength,
              productName: product.name,
              vinName: vehicles[0].vehicle.displayName,
            }
          );
          this.displayErrorSnackBar(message);
          this.showProcessing = false;
          console.log(err);
        })
        .finally(() => {
          this.clearSelected();
          this.showProcessing = false;
        });
    },
    addToGroup(groups) {
      this.showProcessing = true;
      let vehicles = Object.keys(this.selected);
      this.processingMessage = this.$tc(
        'vehicle.processingBar.addVehiclesToGroupMessage',
        vehicles.length,
        { count: vehicles.length }
      );
      let groupIds = groups.map((item) => item.id);

      GroupService.bulkAddVehiclesToGroups(groupIds, vehicles)
        .then((res) => {
          res.data.forEach((obj) => {
            if (obj.successfulVehicles.length > 0) {
              let successMessage = this.$tc(
                'vehicle.toolbar.addToGroup.snackBarMessage.success',
                obj.successfulVehicles.length,
                {
                  nVehicles: this.$tc(
                    'vehicle.nVehicles',
                    obj.successfulVehicles.length,
                    { numberOfVehicles: obj.successfulVehicles.length }
                  ),
                  groupName: obj.segmentInfo.name,
                  vehicleName: obj.successfulVehicles[0].displayName,
                }
              );
              this.displaySnackBar(successMessage);
            }
            if (obj.failedVehicles.length > 0) {
              let errorMessage = this.$tc(
                'vehicle.toolbar.addToGroup.snackBarMessage.error',
                obj.failedVehicles.length,
                {
                  nVehicles: this.$tc(
                    'vehicle.nVehicles',
                    obj.failedVehicles.length,
                    { numberOfVehicles: obj.failedVehicles.length }
                  ),
                  groupName: obj.segmentInfo.name,
                  vehicleName: obj.failedVehicles[0].displayName,
                }
              );
              this.displayErrorSnackBar(errorMessage);
            }
          });
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.clearSelected();
          this.showProcessing = false;
        });
    },
    exportErrorSummaryToCsv(data) {
      let summaryReport = [];
      data.forEach((ccsSetting) => {
        summaryReport.push({
          vin: ccsSetting.vin,
          actionCategory: ccsSetting.actionCategory
            ? ccsSetting.actionCategory
            : 'Unknown',
          message: ccsSetting.message ? ccsSetting.message : 'Unknown',
        });
      });
      const dataToConvert = {
        data: summaryReport,
        filename: 'ErrorSummaryReport',
        delimiter: ',',
        headers: ['VIN', 'ERROR CATEGORY', 'ERROR MESSAGE'],
      };
      convertToCsvFromJson(dataToConvert);
    },
    removeFromGroup(groups) {
      this.showProcessing = true;
      let vehicles = Object.keys(this.selected);
      this.processingMessage = this.$tc(
        'vehicle.processingBar.removeVehiclesFromGroupMessage',
        vehicles.length,
        { count: vehicles.length }
      );
      let groupIds = groups.map((item) => item.id);
      GroupService.bulkRemoveVehiclesFromGroups(groupIds, vehicles)
        .then((res) => {
          res.data
            .forEach((obj) => {
              if (obj.successfulVehicles.length > 0) {
                let successMessage = this.$tc(
                  'vehicle.toolbar.removeFromGroup.snackBarMessage.success',
                  obj.successfulVehicles.length,
                  {
                    nVehicles: this.$tc(
                      'vehicle.nVehicles',
                      obj.successfulVehicles.length,
                      { numberOfVehicles: obj.successfulVehicles.length }
                    ),
                    groupName: obj.segmentInfo.name,
                    vehicleName: obj.successfulVehicles[0].displayName,
                  }
                );
                this.displaySnackBar(successMessage);
              }
              if (obj.failedVehicles.length > 0) {
                let errorMessage = this.$tc(
                  'vehicle.toolbar.removeFromGroup.snackBarMessage.error',
                  obj.failedVehicles.length,
                  {
                    nVehicles: this.$tc(
                      'vehicle.nVehicles',
                      obj.failedVehicles.length,
                      { numberOfVehicles: obj.failedVehicles.length }
                    ),
                    groupName: obj.segmentInfo.name,
                    vehicleName: obj.failedVehicles[0].displayName,
                  }
                );
                this.displayErrorSnackBar(errorMessage);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .finally(() => {
          this.clearSelected();
          this.showProcessing = false;
        });
    },
    attemptUnsubscribe(data) {
      this.showProcessing = true;
      let vehicleNames = Object.values(this.selected);
      this.processingMessage = this.$tc(
        'vehicle.processingBar.unsubscribeMessage',
        this.selectedLength,
        { count: this.selectedLength }
      );
      this.selectedProduct = data.product;
      VehicleService.attemptUnsubscribe(
        this.selectedProduct,
        Object.keys(this.selected),
        data.churnId,
        data.reason
      )
        .then((res) => {
          if (res.conflict) {
            this.selectedProduct = res.selectedProduct;
            this.goodVehicles = res.goodVehicles;
            this.subscribe = false;
            this.conflictingVehicles = res.badVehicles;
            this.showConfirmWhenConflict = true;
            this.showProcessing = false;
          } else {
            let message = this.$tc(
              'vehicle.toolbar.unsubscribeProducts.snackBarMessage.success',
              this.selectedLength,
              {
                count: this.selectedLength,
                productName: data.product.name,
                vinName: vehicleNames[0],
              }
            );
            this.showProcessing = false;
            this.displaySnackBar(message);
            this.clearSelected();
          }
        })
        .catch((err) => {
          console.log(err);
          let message = this.$tc(
            'vehicle.toolbar.unsubscribeProducts.snackBarMessage.error',
            this.selectedLength,
            {
              count: this.selectedLength,
              productName: data.product.name,
              vinName: vehicleNames[0],
            }
          );
          this.displayErrorSnackBar(message);
          this.displayErrorSnackBar(message);
          this.showProcessing = false;
        });
    },
    attemptMarkForDelete(deleteAction) {
      if (deleteAction) {
        this.bulkDelete = true;
        VehicleService.attemptMarkForDelete(Object.keys(this.selected))
          .then((res) => {
            this.enrolledVehicles = cloneDeep(res.data.enrolledVehicles);
            this.notEnrolledVehicles = cloneDeep(res.data.notEnrolledVehicles);
            this.processingVehicles = cloneDeep(res.data.processingVehicles);
            ConsentService.getConsents(Object.keys(this.selected))
              .then((consentResponse) => {
                this.consentedVehicles = cloneDeep(consentResponse.data);
                this.determineBulkDeletePopupDetails();
              })
              .catch((err) => {
                console.log(err);
                this.handleError(err);
              });
          })
          .catch((err) => {
            console.log(err);
            this.handleError(err);
          });
      }
    },
    determineBulkDeletePopupDetails() {
      if (
        this.processingVehicles.length > 0 &&
        this.enrolledVehicles.length > 0
      ) {
        this.setConfirmationDialogMessage('PROCESSING_SUBSCRIBED');
      } else if (
        this.processingVehicles.length > 0 &&
        this.notEnrolledVehicles.length === 0 &&
        this.enrolledVehicles.length === 0
      ) {
        this.setConfirmationDialogMessage('PROCESSING');
      } else if (
        this.processingVehicles.length === 0 &&
        this.enrolledVehicles.length > 0
      ) {
        this.setConfirmationDialogMessage('SUBSCRIBED');
      } else if (
        this.processingVehicles.length > 0 &&
        this.notEnrolledVehicles.length > 0
      ) {
        this.setConfirmationDialogMessage('PROCESSING_UNENROLLED');
      } else if (this.consentedVehicles.length > 0) {
        this.setConfirmationDialogMessage('CONSENTED');
      } else if (
        this.notEnrolledVehicles.length > 0 &&
        this.processingVehicles.length === 0 &&
        this.enrolledVehicles.length === 0
      ) {
        this.setConfirmationDialogMessage('NONE');
      }
    },
    determineBulkDeleteConflict(data) {
      if (
        (this.processingVehicles.length > 0 &&
          this.enrolledVehicles.length > 0) ||
        (this.processingVehicles.length > 0 &&
          this.notEnrolledVehicles.length > 0) ||
        (this.consentedVehicles.length > 0 &&
          (this.processingVehicles.length > 0 ||
            this.enrolledVehicles.length > 0))
      ) {
        this.showConfirmWhenConflict = true;
      } else {
        this.showConfirmWhenConflict = false;
        this.bulkDeconsentAndDisable(data);
      }
    },
    setConfirmationDialogMessage(status) {
      if (status === 'PROCESSING') {
        console.log('status:' + status);
        this.confirmationDialog.confirmationTitle = this.$t(
          'vehicle.editVehicle.unableToDecommissionConfirmationTitle'
        );
        this.confirmationDialog.confirmationMessage = this.$t(
          'vehicle.editVehicle.unableToDecommissionConfirmationMessage'
        );
        this.confirmationDialog.cancelText = this.$t('confirmationDialog.exit');
        this.confirmationDialog.displayConfirm = false;
        this.confirmationDialog.dataQa = 'unableToDelete';
      } else if (status === 'SUBSCRIBED') {
        this.confirmationDialog.confirmationTitle = this.$t(
          'vehicle.editVehicle.decommissionAndUnsubscribeConfirmationTitle'
        );
        this.confirmationDialog.confirmationMessage = this.$t(
          'vehicle.editVehicle.bulkDecommissionAndUnsubscribeConfirmationMessage',
          {
            vehicleOrVehicles: this.$tc(
              'vehicle.editVehicle.vehicleOrVehicles',
              this.enrolledVehicles.length,
              { numVehicle: this.enrolledVehicles.length }
            ),
          }
        );
        this.confirmationDialog.acceptanceText = this.$t(
          'vehicle.editVehicle.decommissionAndUnsubscribe'
        );
        this.confirmationDialog.cancelText = this.$t(
          'confirmationDialog.cancel'
        );
        this.confirmationDialog.displayConfirm = true;
        this.confirmationDialog.dataQa = 'deleteAndUnsubscribe';
        this.confirmationDialog.isChurnRequired = true;
      } else if (
        status === 'PROCESSING_SUBSCRIBED' ||
        status === 'PROCESSING_UNENROLLED'
      ) {
        let remainingCount =
          this.enrolledVehicles.length + this.notEnrolledVehicles.length;
        this.confirmationDialog.confirmationTitle = this.$t(
          'vehicle.editVehicle.decommissionAndUnsubscribeMixedCaseTitle'
        );
        this.confirmationDialog.confirmationMessage = this.$t(
          'vehicle.editVehicle.decommissionAndUnsubscribeMixedCaseMessage',
          {
            vehicleOrVehicles: this.$tc(
              'vehicle.editVehicle.vehicleOrVehicles',
              this.processingVehicles.length,
              { numVehicle: this.processingVehicles.length }
            ),
            remainingVehicleOrVehicles: this.$tc(
              'vehicle.editVehicle.remainingVehicleOrVehicles',
              remainingCount,
              { numRemainingVehicle: remainingCount }
            ),
          }
        );
        this.confirmationDialog.acceptanceText = this.$t(
          'vehicle.editVehicle.acceptanceText'
        );
        this.confirmationDialog.cancelText = this.$t(
          'confirmationDialog.cancel'
        );
        this.confirmationDialog.displayConfirm = true;
        this.confirmationDialog.dataQa = 'deleteAndUnsubscribeMixedCase';
      } else if (status === 'CONSENTED') {
        this.confirmationDialog.confirmationTitle = this.$t(
          'vehicle.toolbar.deconsentAndDecommission.title'
        );
        this.confirmationDialog.confirmationMessage = this.$t(
          'vehicle.toolbar.deconsentAndDecommission.message',
          {
            nVehiclesIsare: this.$tc(
              'vehicle.nVehiclesWithAreOrIs',
              this.consentedVehicles.length,
              { numberOfVehicles: this.consentedVehicles.length }
            ),
            nVehicles: this.$tc(
              'vehicle.nVehicles',
              this.consentedVehicles.length,
              { numberOfVehicles: this.consentedVehicles.length }
            ),
          }
        );
        console.log(this.confirmationDialog.confirmationMessage);
        this.confirmationDialog.acceptanceText = this.$t(
          'vehicle.toolbar.deconsentAndDecommission.acceptanceText'
        );
        this.confirmationDialog.cancelText = this.$t(
          'confirmationDialog.cancel'
        );
        this.confirmationDialog.displayConfirm = true;
        this.confirmationDialog.dataQa = 'deconsentAndDecommission';
      } else {
        console.log(status);
        this.confirmationDialog.confirmationTitle = this.$t(
          'vehicle.editVehicle.decommissionConfirmationTitle'
        );
        this.confirmationDialog.confirmationMessage = this.$t(
          'vehicle.editVehicle.bulkDecommissionConfirmationMessage',
          {
            deleteVehicles: this.$tc(
              'vehicle.editVehicle.deleteVehicles',
              this.notEnrolledVehicles.length,
              { numVehicle: this.notEnrolledVehicles.length }
            ),
          }
        );
        this.confirmationDialog.acceptanceText = this.$t(
          'vehicle.editVehicle.decommission'
        );
        this.confirmationDialog.cancelText = this.$t(
          'confirmationDialog.cancel'
        );
        this.confirmationDialog.displayConfirm = true;
        this.confirmationDialog.dataQa = 'deleteConfirmation';
      }
    },
    handleError(error) {
      this.error = ErrorMessageService.getErrorMessage(error);
      this.showErrorPopup = true;
    },
    tableUpdate() {
      this.getVehicleFilters();
      this.getVehicles();
    },
    displaySnackBar(message) {
      this.addSuccessNotification(message);
      this.tableUpdate();
      if (this.updateAggregates) this.getEnrollmentAggregates();
    },
    displayInformationSnackBar(message) {
      this.addInformationNotification(message);
      this.tableUpdate();
      if (this.updateAggregates) this.getEnrollmentAggregates();
    },
    displayErrorSnackBar(message) {
      this.addErrorNotification(message);
      this.tableUpdate();
    },
    reshowProductDialog() {
      if (this.subscribe) {
        this.$refs.confirmationDialogWithDropDown_0[0].dialog = true;
      } else if (this.bulkDelete) {
        this.$refs.confirmationDialog[0].dialog = true;
      } else {
        this.$refs.confirmationDialogWithDropDown_1[0].dialog = true;
      }
    },
    toggleButton(buttonName, colorName) {
      this[buttonName] = !this[buttonName];
      this[colorName] = this[buttonName] ? 'white' : null;
    },
    allVehicleGridColumns() {
      let columns = AccountConstants.ALL_VEHICLE_GRID_COLUMNS;
      columns.forEach((entry) => {
        entry.name = this.$t(entry.name);
      });
      return columns;
    },
    setInitialFilterFlags() {
      let filter = this.vehicleFilter.status || [];
      if (filter.includes('Action')) {
        this.toggleButton('showAllActionRequired', 'actionBtnSelectedColor');
      }

      if (filter.includes('Processing')) {
        this.toggleButton('showAllProcessing', 'processingBtnSelectedColor');
      }

      if (filter.includes('Subscribed')) {
        this.toggleButton('showAllSubscribed', 'subscribedBtnSelectedColor');
      }
    },
    async setFilterFlag(statusFlag) {
      let statusFilter = [];
      if (statusFlag === 'Action') {
        this.toggleButton('showAllActionRequired', 'actionBtnSelectedColor');
      } else if (statusFlag === 'Processing') {
        this.toggleButton('showAllProcessing', 'processingBtnSelectedColor');
      } else if (statusFlag === 'Subscribed') {
        this.toggleButton('showAllSubscribed', 'subscribedBtnSelectedColor');
      }

      if (this.showAllActionRequired) {
        statusFilter.push('Action');
      }
      if (this.showAllProcessing) {
        statusFilter.push('Processing');
      }
      if (this.showAllSubscribed) {
        statusFilter.push('Subscribed');
      }

      await this.setVehicleFilter({
        name: 'status',
        selected: statusFilter,
      });
    },
    dashify(str) {
      return dashify(str);
    },
    vehicleGridColumnUrl() {
      return AccountConstants.VEHICLE_GRID_COLUMNS_URL;
    },
    getExternalFilterDisplay() {
      let externalFilterDisplay = this.vehicleFilter.externalFilterDisplay;
      if (externalFilterDisplay) {
        return externalFilterDisplay;
      }
      return '';
    },
    clearExternalFilterDisplay() {
      let externalFilterDisplay = this.getExternalFilterDisplay();
      if (
        externalFilterDisplay ===
        this.$tc('groups.unassignedGroupExternalFilterDisplay')
      ) {
        this.toggleVehicleFilter({
          name: 'groups',
          selected: this.vehicleFilter.groups,
        });
      } else {
        this.toggleVehicleFilter({
          name: 'vehicleList',
          selected: this.vehicleFilter.vehicleList,
        });
      }
      this.toggleVehicleFilter({
        name: 'externalFilterDisplay',
        selected: externalFilterDisplay,
      });
    },
    ...mapActions('auth', ['fetchCompanyVehicleLevelProductFamilies']),
    ...mapActions('notification', [
      'addSuccessNotification',
      'addErrorNotification',
      'addInformationNotification',
    ]),
    ...mapActions('vehicle', [
      'addSelected',
      'clearSelected',
      'setTotalVehicles',
      'setTotalFilteredVehicles',
      'setVehicleFilter',
      'setVehicleGridColumns',
      'toggleVehicleFilter',
      'setCcsSettings',
    ]),
    ...mapActions('configuration', {
      fetchConfiguration: 'fetch',
    }),
  },
};
</script>

<style lang="scss" scoped>
@media only screen and (min-width: 1264px) {
  .container.grid-list-md {
    max-width: 100%;
    margin: auto 4px;
  }
}

.download-error-summary {
  text-decoration-line: underline;
}

.export-button {
  margin: 10px 0 6px 0;
  border-color: #2861a4;
}

::v-deep .consent-list-item {
  width: 50%;
  display: inline-block;
  box-sizing: content-box;

  &.text-right {
    text-align: right;
  }
}

.external-filter-chip {
  ::v-deep .v-chip {
    padding: 6px 6px 6px 12px;
  }
}

.confirmation-dialog-content {
  display: flex;
  flex-direction: column;
  height: 100%;
}
</style>
